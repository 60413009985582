const initialState = {
  brands: [],
  colors: ["Black", "Brown", "Silver", "White", "Blue"],
  showDrawer: false,
  myAddress: "",
};

export function startupReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_BRANDS":
      return { ...state, brands: action.payload };
    case "SET_ADDRESS":
      return { ...state, myAddress: action.payload };
    case "TOGGLE_DRAWER":
      return { ...state, showDrawer: action.payload };
    default:
      return state;
  }
}
