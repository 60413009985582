import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./firebase";
import { getCurrentUser } from "./functions/auth";
import { getBrands } from "./functions/product";

const Header = lazy(() => import("./components/nav/Header"));

const Login = lazy(() => import("./pages/auth/Login"));
const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./pages/auth/Register"));
const RegisterComplete = lazy(() => import("./pages/auth/RegisterComplete"));
const UserRoute = lazy(() => import("./components/routes/UserRoute"));
const History = lazy(() => import("./pages/user/History"));
const Password = lazy(() => import("./pages/user/Password"));
const Wishlist = lazy(() => import("./pages/user/Wishlist"));
const AdminRoute = lazy(() => import("./components/routes/AdminRoute"));
const AdminDashboard = lazy(() => import("./pages/admin/AdminDashboard"));
const CategoryCreate = lazy(() =>
  import("./pages/admin/category/CategoryCreate")
);
const CategoryUpdate = lazy(() =>
  import("./pages/admin/category/CategoryUpdate")
);
const SubCategoryCreate = lazy(() =>
  import("./pages/admin/subCategory/SubCategoryCreate")
);
const SubCategoryUpdate = lazy(() =>
  import("./pages/admin/subCategory/SubCategoryUpdate")
);
const ProductCreate = lazy(() => import("./pages/product/ProductCreate"));
const AllProducts = lazy(() => import("./pages/product/AllProducts"));
const ProductUpdate = lazy(() => import("./pages/product/ProductUpdate"));
const Product = lazy(() => import("./pages/Product"));
const CategoryHome = lazy(() => import("./pages/category/CategoryHome"));
const SubCategoryHome = lazy(() => import("./pages/sub/SubCategoryHome"));
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const BrandCreate = lazy(() => import("./pages/product/BrandCreate"));
const SideDrawer = lazy(() => import("./components/drawer/SideDrawer"));
const Checkout = lazy(() => import("./pages/Checkout"));
const CreateCouponPage = lazy(() => import("./pages/coupon/CreateCouponPage"));

function App() {
  const dispatch = useDispatch();
  // const history = useHistory();

  // load brands
  useEffect(() => {
    getBrands().then((res) =>
      dispatch({
        type: "SET_BRANDS",
        payload: res.data,
      })
    );
  }, []);

  // to check firebase auth state
  useEffect(() => {
    // console.log("APP.JS USEEFFCT");
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      console.log("APP.JS USEEFFCT");
      if (user) {
        const idTokenResult = await user.getIdTokenResult();

        getCurrentUser(idTokenResult.token)
          .then((res) => {
            console.log("CREATE OR UPDATE USER RESPONSE", res);
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                email: res.data.email,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
              },
            });
          })
          .catch((err) => console.log(err));
      }
    });
    //
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Suspense className="App" fallback={"Loading ..."}>
      <Header></Header>
      <SideDrawer></SideDrawer>
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route
          exact
          path="/register/complete/:email"
          component={RegisterComplete}
        />

        <Route path="/product/:slug" component={Product}></Route>

        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AdminRoute exact path="/admin/category" component={CategoryCreate} />
        <AdminRoute
          exact
          path="/admin/category/:slug"
          component={CategoryUpdate}
        />
        <AdminRoute
          exact
          path="/admin/subCategory"
          component={SubCategoryCreate}
        />
        <AdminRoute
          exact
          path="/admin/subCategory/:slug"
          component={SubCategoryUpdate}
        />
        <UserRoute exact path="/user/history" component={History} />
        <UserRoute exact path="/user/password" component={Password} />
        <UserRoute exact path="/user/wishlist" component={Wishlist} />
        <UserRoute exact path="/checkout" component={Checkout}></UserRoute>

        <AdminRoute
          exact
          path="/admin/product"
          component={ProductCreate}
        ></AdminRoute>

        <AdminRoute
          exact
          path="/admin/brand"
          component={BrandCreate}
        ></AdminRoute>

        <AdminRoute
          exact
          path="/admin/product/:slug"
          component={ProductUpdate}
        ></AdminRoute>

        <AdminRoute
          exact
          path="/admin/products"
          component={AllProducts}
        ></AdminRoute>

        <AdminRoute exact path="/admin/coupon" component={CreateCouponPage} />

        <Route exact path="/category/:slug" component={CategoryHome}></Route>
        <Route exact path="/sub/:slug" component={SubCategoryHome}></Route>
        <Route exact path="/shop" component={Shop}></Route>
        <Route exact path="/cart" component={Cart}></Route>
      </Switch>
    </Suspense>
  );
}

export default App;
