import axios from "axios";

export const createProduct = async (product, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/product`, product, {
    headers: {
      authtoken,
    },
  });

export const createBrand = async (brand, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/brand`, brand, {
    headers: {
      authtoken,
    },
  });

export const getBrands = async () =>
  await axios.get(`${process.env.REACT_APP_API}/brands`);

export const getProducts = async (sort, order, page) =>
  await axios.post(`${process.env.REACT_APP_API}/products`, {
    sort,
    order,
    page,
  });

export const getProductsByCount = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/products/${count}`);

export const getProductsTotal = async (count) =>
  await axios.get(`${process.env.REACT_APP_API}/products/total`);

export const removeProduct = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/product/${slug}`, {
    headers: {
      authtoken,
    },
  });

export const getProduct = async (slug) =>
  await axios.get(`${process.env.REACT_APP_API}/product/${slug}`);

export const updateProduct = async (slug, product, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/product/${slug}`, product, {
    headers: {
      authtoken,
    },
  });

export const rateProduct = async (star, name, authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/product/star/${name}`,
    {
      star,
    },
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const getRelatedProducts = async (productId) =>
  await axios.get(`${process.env.REACT_APP_API}/product/related/${productId}`);

export const searchFilterProducts = async (query) => {
  return await axios.post(`${process.env.REACT_APP_API}/search/filters`, query);
};
