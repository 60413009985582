let initialState = [];

try {
  let cartItem = window.localStorage.getItem("cart");
  if (cartItem) {
    initialState = JSON.parse(cartItem);
  }
} catch (error) {
  initialState = [];
}

// load cart items from localstorage
export function cartReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_TO_CART":
      return action.payload;
    default:
      return state;
  }
}
